import React from 'react'
import { useState } from 'react';

function Certificationcard(props) {
    const certificate = props.certificate;
    
    const [isHover, setIsHover] = useState(false);

    const handleMouseEnter = () => {
        setIsHover(true);
    };
    const handleMouseLeave = () => {
        setIsHover(false);
    };

    const styles = {
        boxShadow: isHover ? `0 5px 15px ${certificate.color_code}`:`0px 2px 5px ${certificate.color_code}`,
        border: `1px solid ${certificate.color_code}`,
    };

    return (
        <div className="cert-card" style={styles} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
        <div className="content">
          <a
            href={certificate.certificate_link}
            target="_blank"
            rel="noopener noreferrer"
          >
            <div className="content-overlay"></div>
            <div
              className="cert-header"
              style={{ backgroundColor: certificate.color_code }}
            >
              <img
                className="logo_img"
                src={require(`../../Assets/${certificate.logo_path}`)}
                alt={certificate.alt_name}
              />
            </div>
          </a>
        </div>
        <div className="cert-body">
          <h2 className="cert-body-title">
            {certificate.title}
          </h2>
          <h3
            className="cert-body-subtitle"
          >
            {certificate.subtitle}
          </h3>
        </div>
      </div>
  )
}

export default Certificationcard