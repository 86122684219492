import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import ProjectCard from "./ProjectCards";
import Particle from "../Particle";
import Delicious from "../../Assets/Projects/delicious.png";
import Watchlist from "../../Assets/Projects/watchlist.png";
import Nlp from "../../Assets/Projects/nlp.png";
import Premdictor from "../../Assets/Projects/premdictor.png";
import Ecom from "../../Assets/Projects/ecom.png";
import Mesh from "../../Assets/Projects/mesh.png";

function Projects() {
  return (
    <Container fluid className="project-section">
      <Particle />
      <Container>
        <h1 className="project-heading">
          <strong className="purple">Projects</strong>
        </h1>
        <p style={{ color: "white" }}>
          Here are a few projects I've worked on recently.
        </p>
        <Row style={{ justifyContent: "center", paddingBottom: "10px", "display": "flex" }}>
          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={Ecom}
              isBlog={false}
              title="Ecommerce Store"
              description="A web app created with the MERN Stack. Allow users to create an account and buy product and pay through paypal. Admin user can create and modify products."
              ghLink="https://github.com/VirochaanRG/ecommerce-platform"
            />
          </Col>
          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={Delicious}
              isBlog={false}
              title="Recipes Repository"
              description="A Web App created using React getting data from an Recipe Api and dynamically outputting it."
              ghLink="https://github.com/VirochaanRG/delicious"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={Watchlist}
              isBlog={false}
              title="Cryptos Watchlist"
              description="A Crypto Currency Watchlist with a front-end with Vue and a backend with Nodejs with MongoDB used to store data. Dynamically update with crypto-compare api"
              ghLink="https://github.com/VirochaanRG/watchlist"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={Premdictor}
              isBlog={false}
              title="Premier League Predictor"
              description="Using premier league team data, a python model would use various algorithms in order to predict the potential final league table. This data was then rendered through flask"
              ghLink="https://github.com/VirochaanRG/Premdictor"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={Nlp}
              isBlog={false}
              title="NLP with Python"
              description="Basic Nlp Algorithm to analyze a financial text and extract keywords, symbols, and tickers in order to make informed decisions using Spacy and Python."
              ghLink="https://github.com/VirochaanRG/Premdictor"
            />
          </Col>
          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={Mesh}
              isBlog={false}
              title="Island Mesh Generator"
              description="Java Command Line tool which generates and visualizes mesh based on documented parameters. Outputs to a mesh data type and generates .OBJ files for viewing in Blender."
              ghLink="https://github.com/VirochaanRG/Premdictor"
            />
          </Col>
        </Row>
      </Container>
    </Container>
  );
}

export default Projects;
