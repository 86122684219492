import React from "react";
import { Col, Row } from "react-bootstrap";
import {
  DiPython
} from "react-icons/di";
import { SiNumpy, SiPandas, SiRstudio, SiScikitlearn, SiTensorflow } from "react-icons/si";



const Machinestack = () => {
  return (
    <Row style={{ justifyContent: "center", paddingBottom: "50px", paddingLeft: "50px" }}>
      <Col xs={4} md={2} className="tech-icons">
        <DiPython />
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <SiNumpy/>
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <SiPandas/>
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <SiScikitlearn/>
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <SiRstudio/>
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <SiTensorflow/>
      </Col>
    </Row>
  )
}

export default Machinestack