import React from 'react'
import { Col, Row } from "react-bootstrap";
import { FaAws, FaDocker } from 'react-icons/fa';
import ec2 from "../../Assets/Skills/ec2.png";
import cloudformation from "../../Assets/Skills/cloudformation.png";
import dynamodb from "../../Assets/Skills/dynamodb.png";
import s3 from "../../Assets/Skills/s3.png";




const Cloudstack = () => {
  return (
    <Row style={{ justifyContent: "center", paddingBottom: "50px", paddingLeft: "50px" }}>
      <Col xs={4} md={2} className="tech-icons">
        <FaAws />
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <FaDocker />
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <img src={ec2} className="skill-image"/>
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <img src={cloudformation} className="skill-image"/>
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <img src={dynamodb} className="skill-image"/>
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <img src={s3} className="skill-image"/>
      </Col>
    </Row>
  )
}

export default Cloudstack