import React from 'react';
import { useState } from 'react';
import { Button, Col, Container, Row } from 'react-bootstrap';
import Particle from '../Particle';
import Fullstack from './Fullstack';
import {BsStack} from 'react-icons/bs';
import {VscGraphLine} from 'react-icons/vsc';
import {AiFillCloud} from 'react-icons/ai';
import Machinestack from './Machinestack';
import Cloudstack from './Cloudstack';
import Certificationcard from './Certificationcard';



const certifications = {
  certifications: [
    {
      title: "AWS Certified Developer",
      subtitle: "AWS Developer experience with cloud technologies",
      logo_path: "awscert.png",
      certificate_link: "#",
      alt_name: "AWS",
      color_code: "#FFA500"
    },
    {
      title: "Data Science Specialization",
      subtitle: "Data Science with John Hopkins University",
      logo_path: "jhu.png",
      certificate_link: "#",
      alt_name: "JHU",
      color_code: "#ADD8E6"
    },
    {
      title: "Google Student Club",
      subtitle: "Member of student development club at Mcmaster",
      logo_path: "gsdc.png",
      certificate_link: "",
      alt_name: "GSDC",
      color_code: "#5ced73"
    }
  ]

}

const Skills = () => {
    const [showStack, setShowStack] = useState(true);
    const [showMachine, setShowMachine] = useState(false);
    const [showData, setShowData] = useState(false);
    
  return (
    <Container fluid className="skill-section">
      <Container>
        <h1 style={{ fontSize: "2.1em", paddingBottom: "20px" }}>
            <strong className="purple">Skills</strong>
        </h1>
        <Row style={{ justifyContent: "center", padding: "10px" }}>
          <Col
            md={6}
            style={{
              justifyContent: "center",
              paddingTop: "30px",
              paddingBottom: "50px",
            }}
          >
            <div className=''>
                <button onClick={() =>{
                        setShowStack(true);
                        setShowMachine(false);
                        setShowData(false);
                    }} className={showStack ? 'skill-button-active' : 'skill-button' }>
                    <BsStack color={showStack ? '#0c203f': '#83caf4'} size={30}/>
                </button>
                <button onClick={() =>{
                        setShowStack(false);
                        setShowMachine(true);
                        setShowData(false);
                    }} className={showMachine ? 'skill-button-active' : 'skill-button' }>
                    <VscGraphLine color={showMachine ? '#0c203f': '#83caf4'} size={30}/>
                </button>
                <button onClick={() =>{
                        setShowStack(false);
                        setShowMachine(false);
                        setShowData(true);
                    }} className={showData ? 'skill-button-active' : 'skill-button' }>
                    <AiFillCloud color={showData ? '#0c203f': '#83caf4'} size={30}/>
                </button>
            </div>
            <div className="">
                    {showStack===true ? 
                        <div>
                            <div className='skill-title'>Full Stack Development</div>
                            <div className='skill-text'>There are various technologies and frameworks that I have experience with in regards to creating Full-Stack Applications. Firstly, I have experience working with React a javascript library as well as Next.js which is a framework that builds upon react. For styling I have experience with tailwindcss as well as bootstrap. For the backend I have experience with various technologies including nodejs, express, sanity and mongodb and mysql for databases. I am currently working on learning flask which is a python web app framework.</div>
                        </div>
                    : null}
                    {showMachine===true ? 
                        <div>
                        <div className='skill-title'>Machine Learning and Data Analytics</div>
                            <div className='skill-text'>Experience using python and R to create algorithms and models to perform data analysis and use it to make informed predictions. In conjunction with both my cloud computing and my skills using web app can allow us to easily visualize the data and models. I have experience working with numpy, pandas, scikit-learn and tensor flow in python as well as caret in R.</div>
                        </div>
                    : null}
                    {showData===true ? 
                        <div>
                            <div className='skill-title'>Cloud Computing</div>
                            <div className='skill-text'>Extensive Experience using various AWS technologies and tools in order to create and host fully fledged web apps. In addition to this I have experience using various container services and the registry. Also skilled in using the cloud formation stack and the various associated tools. Finally experience using cloud ssh connections and cloud9 IDE.</div>
                        </div>
                    : null}
                </div>
          </Col>
          <Col
            md={5}
            style={{ paddingTop: "50px", paddingBottom: "50px" }}
            className="about-img"
          >
            {showStack === true ? 
              <Fullstack/>            
              : null
            }
            {showMachine === true ? 
              <Machinestack/>            
              : null
            }
            {showData === true ? 
              <Cloudstack/>            
              : null
            }
          </Col>
        </Row>
        <h1 style={{ fontSize: "2.1em", paddingBottom: "20px" }}>
            <strong className="purple">Certifications/Experience</strong>
        </h1>
        <div className="certs-body-div">
          {certifications.certifications.map((cert) => {
            return <Certificationcard certificate={cert}/>;
          })}
        </div>
      </Container>
      <Particle/>                    
    </Container>
  )
}

export default Skills